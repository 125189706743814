<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
   
    <div id="page-content" v-if="!viLoader">
      <!-- <div class="dispflex">
        <h1 class="hdrtitle" style="margin-top: 0px;">School Hub</h1>
        <button type="button" class="backbtn" @click="previousRoute">
               <i class="fa fa-arrow-left"></i>
              </button>
      </div> -->
      <div class="webNew-dispflex"  >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Hub
       </h4>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-book-reader"></i
                ></span>
                <h1>Manage Classes and Subjects</h1>
                
              </div>
               <!-- <div style="margin-top:10px" class="flexitm">
                   <input type="text" v-model="searchWords" placeholder="Search Class" >
                 </div> -->
              <div class="form-row" style="width: 20%" v-if="currentTab == 1">
                <div class="form-group input-group col-md-6" style="margin: 0">
                  <span class="has-float-label">
                    <select v-model="selectClass" class="form-control form-input" @change="setToClassAndSection(selectClass)" id="deptlistid">
                      <option :value="null">--Select--</option>
                      <option
													v-for="(item, index) in fromClassList"
													:key="index"
													:value="item._id"
													>
													{{ item.className }}
													</option>
                    </select>
                    <label for="acyearid"
                      >Class <span class="required">*</span></label
                    >
                  </span>
                </div>
                 <div class="form-group input-group col-md-6" style="margin: 0">
                  <span class="has-float-label">
                    <select v-model="selectSection" @change="getSectionRecords(selectSection)" class="form-control form-input" id="deptlistid">
                      <option :value="null">--Select--</option>
                     <option
													v-for="(item, index) in fromSectionList"
													:key="index"
													:value="item._id"
													>
													{{ item.name }}
													</option>
                    </select>
                    <label for="acyearid"
                      >Classroom <span class="required">*</span></label
                    >
                  </span>
                </div>
                
              </div>
              <div class="mt-1" style="margin-right: 31px;">
                
                <button class="btn gry_bg tooltipt mpopup twoicn" v-b-modal.addclass v-if="currentTab == 0">
                  
                   <i class="fas fa-book-open user1" style="top:3px; left:2px;"></i>
                 <p class="user2"><i class="fas fa-plus"></i></p>
                 
                  <span class="tooltiptext">Create Class</span>
                </button>
                 <button class="btn gry_bg tooltipt mpopup twoicn" @click.prevent="showSectionPopUp" v-if="currentTab == 1">
                 
                   <i class="fas fa-book-open user1" style="top:3px; left:2px;"></i>
                 <p class="user2"><i class="fas fa-plus"></i></p>
                 
                  <span class="tooltiptext">Create Class/Classroom</span>
                </button>
                <button class="btn gry_bg tooltipt mpopup twoicn" @click.prevent="showSubjectPopUp" v-if="currentTab == 2">
                
                   <i class="fas fa-book-open user1" style="top:3px; left:2px;"></i>
                 <p class="user2"><i class="fas fa-plus"></i></p>
                 
                  <span class="tooltiptext">Create Subject</span>
                </button>
              </div>
            </div>
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%;background:none">
               
                <input
                  type="radio"
                  name="tabs"
                  @click="setActiveTab(1)"
                  id="classesSection"
                  :checked="currentTab == 1 ? 'checked' : ''"
                />
                <label for="classesSection" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Classrooms[{{ sectionList.length }}]</label>
                <div class="tab">
                  <div >
                    <div v-if="Object.keys(sectionGroupByClass).length > 0">
                      <div class="admintbl pb-3 mt-1">
                        <div>
                          <div class="row cust-bg mb-1" style="border-radius: 6px;
    /* color: #000; */
    background: #f0f8ff;">
                            <div class="col-2 cell" style="padding-left: 2%;">Class</div>
                            <div class="col-2 cell">Classroom</div>
                                  
                                  <div class="col-2 cell" style="text-align: center;">Students</div>
                                  <div class="col-2 cell" style="text-align: center;">Teachers</div>
                                  <div class="col-2 cell"></div>
                                  <div class="col-2 cell">Action</div>
                          </div>
                          <div
                            v-for="(data, index) in Object.keys(sectionGroupByClass)"
                            :key="index"
                            style="border-radius: 14px;
    border: 1px solid rgb(192, 196, 233);"
    class="mb-1"
                          >
                            <div style="border-radius: 12px 12px 12px 12px;margin-right: 0px !important;margin-left: 0px !important;"
                              class="row cust-lr mt-1"
                            >
                              
                              <div class="col-2 cell" style="font-weight: bold;padding-left: 2%;">
                                {{ data }}
                              </div>
                              <div class="col-2 cell">
                              </div>
                              <div class="col-2 cell">
                              </div>
                              <div class="col-2 cell">
                              </div>
                              <div class="col-2 cell">
                              </div>
                              <div class="col-2 cell">
                                <div
                            class="dispflex"
                            style="justify-content: stretch"
                          >
                                <b-dropdown
                              id="dropdown-1"
                              text=""
                              style="margin-right: 3px"
                            >
                              <b-dropdown-item @click="openAssignSubjectClass(sectionGroupByClass[data])">Assign Subjects</b-dropdown-item>
                              <!-- <b-dropdown-item @click="showClassEditModal(sectionGroupByClass[data])">Edit</b-dropdown-item>
                              <b-dropdown-item @click="showDeleteModal(sectionGroupByClass[data][0].class._id)">Delete</b-dropdown-item> -->

                            </b-dropdown>
                            <button  @click="showClassEditModal(sectionGroupByClass[data])"
                                      class="btn round orange tooltipt"
                                    >
                                      <i class="fas fa-edit"></i
                                      ><span class="tooltiptext">Edit</span>
                                    </button>
                                <button
                                    class="btn round red tooltipt"
                                    @click="showDeleteModal(sectionGroupByClass[data][0].class._id)"
                                  >
                                    <i class="far fa-trash-alt"></i>
                                    <span class="tooltiptext">Delete</span>
                                  </button>
                                    <!-- <button   @click="openAssignSubjectClass(sectionGroupByClass[data])"
                                      class="btn round orange tooltipt" style="background: #e7a1de;"
                                    >
                                    <i class="fas fa-book-open user1" style="top:3px; left:2px;padding: 0px 6px 0 5px;"></i>
                                     <span class="tooltiptext">Assign Subjects</span>
                                    </button>
                              <button  @click="showClassEditModal(sectionGroupByClass[data])"
                                      class="btn round orange tooltipt"
                                    >
                                      <i class="fas fa-edit"></i
                                      ><span class="tooltiptext">Edit</span>
                                    </button>
                                <button
                                    class="btn round red tooltipt"
                                    @click="showDeleteModal(sectionGroupByClass[data][0].class._id)"
                                  >
                                    <i class="far fa-trash-alt"></i>
                                    <span class="tooltiptext">Delete</span>
                                  </button> -->
                              </div> </div>
                            
                            </div>
                            <div
                              class="row cust-lr mt-2 mb-1"
                              v-for="(ter, issndex) in sectionGroupByClass[data]"
                              :key="issndex"
                            >
                              <div class="col-2 cell"></div>
                            
                              <div class="col-2 cell"> {{ ter.name }}</div>
                              <div class="col-2 cell"> 
                                
                                 <div  data-title="Classes" v-if="ter.student.length > 0" style="text-align: center;">
                          <a style="font-weight: 600;color: green;text-align: center" href="javascript:void(0);"  @click.prevent="redirectRouteToViewStudent(ter._id,ter.class._id)">{{ ter.student.length }} </a>
                        </div>
                         <div  data-title="Classes" v-else style="text-align: center;">
                         {{ ter.student.length }}
                          
                        </div>
                                </div>
                              <div class="col-2 cell" style="text-align: center;"> {{ getuniqeTeachers(ter.assignedTeachers).length }}</div>
                              <div class="col-2 cell"> </div>
                              <div class="col-2 cell"> 
                                <div
                            class="dispflex"
                            style="justify-content: stretch"
                          >
                                <b-dropdown
                              id="dropdown-1"
                              text=""
                              style="margin-right: 3px"
                            >
                              <b-dropdown-item @click="enableAssignTeachersModal(sectionGroupByClass[data],issndex,data)">Assign Teachers</b-dropdown-item>
                              <b-dropdown-item @click="showSectionEditModal(ter._id,data)">Edit</b-dropdown-item>
                              <b-dropdown-item @click="showSectionDeleteModal(ter._id,sectionGroupByClass[data][0].class._id)">Delete</b-dropdown-item>

                            </b-dropdown>
                            </div>
                                <!-- <button  @click="showSectionEditModal(ter._id,data)"
                                      class="btn round orange tooltipt"
                                    >
                                      <i class="fas fa-edit"></i
                                      ><span class="tooltiptext">Edit</span>
                                    </button>
                                    <button 
                                      class="btn round blue tooltipt"
                                      @click="enableAssignTeachersModal(sectionGroupByClass[data],issndex,data)"
                                    >
                                      <i class="fas fa-user-tie"></i
                                      ><span class="tooltiptext">Assign Teachers</span>
                                    </button>
                                  
                                    <button :disabled="getuniqeTeachers(ter.assignedTeachers).length > 0 || ter.student.length > 0"
                                      class="btn round red tooltipt"
                                      @click="showSectionDeleteModal(ter._id,sectionGroupByClass[data][0].class._id)"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                      <span class="tooltiptext">Delete</span>
                                    </button> -->
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    
                    </div>
                   
                  </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  :checked="currentTab == 3 ? 'checked' : ''"
                  @click="setActiveTab(3)"
                  id="assignedClass"
                />
                <label for="assignedClass" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">View Allotted Teachers</label>
                <div class="tab">
                  <div >
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Teacher Name</div>
                        <div class="cell">Class</div>
                        <div class="cell">Section</div>
                        <div class="cell">Subject</div>
                        
                        <div class="cell">Admin</div>
                        <div class="cell">Last Modified By</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(data, index) in assignedTeacherList"
                        :key="index"
                      >
                       
                        <div class="cell" data-title="Department" v-if="data.teacherId">
                          {{ getTeacherName(data.teacherId) }}
                        </div>
                        <div class="cell" data-title="Department" v-if="data.teacherId">
                          {{ data.class.className }}
                        </div>
                        <div class="cell" data-title="Department" v-if="data.teacherId">
                          {{ data.section }}
                        </div>
                         <div class="cell" data-title="Department" v-if="data.teacherId">
                          {{ getSubjectName(data.subjectId) }}
                        </div>
                        <div v-if="data.teacherId" class="cell" data-title="Classes" :style="data.isAdmin ? 'color: #64db17;' : 'color: red;'">
                          {{ data.isAdmin ? 'Yes' : 'No' }} 
                        </div>
                        <!-- <div v-else class="cell" data-title="Classes" style="color: red;">
                          No 
                        </div> -->
                           <div class="cell" data-title="getUserName" v-if="data.teacherId">
                          {{ getUserName }}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <input type="radio" :checked="currentTab == 2 ? 'checked' : ''" @click="setActiveTab(2)" name="tabs" id="subcoursetab" />
                <label for="subcoursetab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;"
                  >Subject & Courses </label
                >
                <div class="tab">
                  <div >
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Subject Name</div>
                                                <div class="cell">Curricular</div>

                        <div class="cell">Last Modified By</div>
                        <div class="cell">Last Modified Date</div>
                        <div class="cell">Action</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(subject, index) in subjectListView"
                        :key="index"
                      >
                     
                        <div class="cell" data-title="Subject Name">
                          {{ subject.subjectName }}
                        </div>
                        <div class="cell" data-title="Subject Name">
                          {{ subject.isCurricularActivities ? 'Yes' : 'No' }}
                        </div>
                        
                           <div class="cell" data-title="getUserName">
                          {{ subject.updatedBy ? subject.updatedBy.firstName + " " +  subject.updatedBy.lastName : '' }}
                        </div>
                         <div  class="cell" data-title="Role">
                          {{ subject.updatedDate | dateFormat }}
                        </div>
                        <div class="cell" data-title="Action">
                          <button @click="showEditSubjectModal(subject._id)"
                            class="btn round orange tooltipt"
                          >
                            <i class="fas fa-edit"></i
                            ><span class="tooltiptext">Edit</span>
                          </button>
                          <button v-if="getAssignedSubjectCount(subject._id)"
                            class="btn round red tooltipt"
                            disabled
                           
                          >
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext" style="left: -96px;width: 336px;">Subject linked to Teachers, hence it cannot be deleted</span>
                          </button>
                          <button v-else
                            class="btn round red tooltipt"
                             @click="showSubjectDeleteModal(subject._id)"
                          >
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext">Delete</span>
                          </button>
                          
                        </div>
                      </div>
                      
                    </div>
                     <div class="widjetfooter text-right dk_iconsml">
             
              <div class="text-center mt-4" v-if="subjectListView.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="subjectCurrentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToSubjectPreviousPage">&laquo;</a>
                  <a
                    v-for="(item, index) in subjectlastPageNumber"
                    :key="index"
                    v-on:click="subjectSelected = item"
                    v-bind:class="{ active: subjectSelected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="subjectCurrentPageNumber === subjectlastPageNumber ? 'pointer-events: none;' : ''" @click="goToSubjectNextPage">&raquo;</a>
                </div>
              </div>
           </div>
                  </div>
                </div>
                

    
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
    <div>
    <b-modal id="addclass" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Create Class</h2>
        <br />
        <div>
          <form
            data-vv-scope="classvalidate"
            id="stuprofile"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                    name="department"
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="classObj.department"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in departMentList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.departmentName }}
                    </option>
                  </select>
                 
                  <label for="deptid" 
                    >Department </label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    @input="checkClassName(classObj.className)"
                    name="class Name"
                    v-model="classObj.className"
                    class="form-control form-input"
                    id="clsnameid"
                    placeholder="Class Name"
                  />
                  <label
                    v-if="errors.first('classvalidate.class Name') || !isClassNameAvailable "
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('classvalidate.class Name') ? 'Class name is required ' : 'Class name already exist. please use a different one.' }} 
                    </label
                  >

                  <label for="clsnameid" v-else
                    >Class Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    name="section"
                    v-model="classObj.section"
                    class="form-control form-input"
                    id="clsectid"
                    placeholder="Class Section"
                  />

                  <label
                    v-if="errors.first('classvalidate.section')"
                    class="vi-error"
                    style="top: -1em"
                    >Class section is required</label
                  >

                  <label for="clsectid" v-else
                    >Class Section <span class="required">*</span></label
                  >
                  <datalist id="classsect">
                    <option value="Sec - A">Sec - A</option>
                    <option value="Sec - B">Sec - B</option>
                    <option value="Sec - C">Sec - C</option>
                  </datalist>
                </span>
              </div>
               <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                 <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click="addTerms"
                >
                 Add Terms
                </button>
              </div> -->
            </div>
            <!-- <div class="form-row">
              <div class="form-group col-md-12 mt-3 pr-md-3">
                <div class="widjetcontent tbllist mt-4">
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Terms</div>
                        <div class="cell">Start Date</div>
                        <div class="cell">End Date</div>
                        <div class="cell">Duration in months</div>
                        <div class="cell">Action</div>

                      </div>

                       <div
                        class="resrow"
                        v-for="(data, index) in classObj.terms"
                        :key="index"
                      >
                        <div class="cell" data-title="Teacher">
                          {{ data.name }}
                        </div>
                        <div class="cell" data-title="Teacher" v-if="index == 0">
                           <input
                            @input="setTermDates(data.startDate,data.endDate,index,'start')"
                            type="date"
                            :min="minDate"
                            :max="maxDate"
                            :name="index + 'start'"
                            v-validate="'required'"
                            v-model="data.startDate"
                            onkeydown="return false" 
                            class="form-control form-input"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classvalidate.${index + 'start'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >Start Date is required</label
                          >

                        
                        </div>
                        <div class="cell" data-title="Teacher" v-else>
                           <input
                            @input="setTermDates(data.startDate,data.endDate,index,'start')"
                            type="date"
                            :disabled="(!classObj.terms[index - 1].startDate || !classObj.terms[index - 1].endDate)"
                            :min="setminStartDate(classObj.terms[index - 1].endDate)"
                            :max="maxDate"
                            :name="index + 'start'"
                            v-validate="'required'"
                            v-model="data.startDate"
                            onkeydown="return false" 
                            class="form-control form-input"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classvalidate.${index + 'start'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >Start Date is required</label
                          >

                        
                        </div>
                        <div class="cell" data-title="Teacher" v-if="index == 0">
                           <input
                            type="date"
                            :min="data.startDate"
                            :max="maxDate"
                            v-model="data.endDate"
                            @input="setTermDates(data.startDate,data.endDate,index,'end')"
                            onkeydown="return false" 
                            v-validate="'required'"
                            :name="index + 'end'"
                            class="form-control form-input"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classvalidate.${index + 'end'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >End Date is required</label
                          >
                        </div>
                        <div class="cell" data-title="Teacher" v-else>
                           <input
                            type="date"
                            :min="data.startDate"
                            :max="maxDate"
                            :disabled="(!classObj.terms[index - 1].startDate || !classObj.terms[index - 1].endDate || !data.startDate)"

                            v-model="data.endDate"
                            @input="setTermDates(data.startDate,data.endDate,index,'end')"
                            onkeydown="return false" 
                            v-validate="'required'"
                            :name="index + 'end'"
                            class="form-control form-input"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classvalidate.${index + 'end'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >End Date is required</label
                          >
                        </div>

                       
                       <div class="cell" data-title="Teacher" style="padding-left: 5%;">
                          {{ data.months }}
                        </div>
                        <div class="cell" data-title="Teacher" >
                            <button :disabled="index != (classObj.terms.length - 1)" v-if="index != 0" class="btn round red tooltipt" @click.prevent="deleteTerms(index)">
                            <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
                </div> -->
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"> -->
                <!-- <button
                  type="button"
                  class="btn btnsml mpopup"
                  title="Create Custom Field"
                >
                  <i class="fas fa-plus"></i> <span>Custom Field</span>
                </button> -->
              <!-- </div> -->
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="createClass"
                >
                    <span>Save</span>
                </button>
                <button
                  @click.prevent="closePopUp"
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
     <b-modal id="editclass" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeUpdatePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Update Class</h2>
        <br />
        <div>
          <form
            data-vv-scope="classEditvalidate"
            id="stuprofile"
            accept-charset="utf-8"
          >
            <div class="form-row">
          
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    @input="checkClassName(classEditObj.className)"
                    v-validate="'required'"
                    type="text"
                    name="class Name"
                    v-model="classEditObj.className"
                    class="form-control form-input"
                    id="clsnameid"
                    placeholder="Class Name"
                  />
                 <label
                    v-if="errors.first('classEditvalidate.class Name') || !isClassNameAvailable "
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('classEditvalidate.class Name') ? 'Class name is required ' : 'Class name already exist. please use a different one.' }} 
                    </label
                  >

                  <label for="clsnameid" v-else
                    >Class Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                 <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click="addEditTerms"
                >
                 Add Terms
                </button>
              </div> -->
            </div>
             <!-- <div class="form-row">
              <div class="form-group col-md-12 mt-3 pr-md-3">
                <div class="widjetcontent tbllist mt-4">
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Terms</div>
                        <div class="cell">Start Date</div>
                        <div class="cell">End Date</div>
                        <div class="cell">Duration in months</div>
                         <div class="cell">Action</div>
                      </div>

                       <div
                        class="resrow"
                        v-for="(data, index) in classEditObj.terms"
                        :key="index"
                      >
                        <div class="cell" data-title="Teacher">
                          {{ data.name }}
                        </div>
                        <div class="cell" data-title="Teacher" v-if="index == 0">
                           <input
                            @input="setTermEditDates(data.startDate,data.endDate,index,'start')"
                            type="date"
                            :min="minDate"
                            :max="maxDate"
                            :name="index + 'editstart'"
                            v-validate="'required'"
                            v-model="data.startDate"
                            onkeydown="return false" 
                            class="form-control form-input"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classEditvalidate.${index + 'editstart'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >Start Date is required</label
                          >

                        
                        </div>
                        <div class="cell" data-title="Teacher" v-else>
                           <input
                            @input="setTermEditDates(data.startDate,data.endDate,index,'start')"
                            type="date"
                            :disabled="(!classEditObj.terms[index - 1].startDate || !classEditObj.terms[index - 1].endDate)"
                            :min="setminStartDate(classEditObj.terms[index - 1].endDate)"
                            :max="maxDate"
                            :name="index + 'editstart'"
                            v-validate="'required'"
                            v-model="data.startDate"
                            onkeydown="return false" 
                            class="form-control form-input"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classEditvalidate.${index + 'editstart'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >Start Date is required</label
                          >

                        
                        </div>
                        <div class="cell" data-title="Teacher" v-if="index == 0">
                           <input
                            type="date"
                            :min="data.startDate"
                            :max="maxDate"
                            v-model="data.endDate"
                            @input="setTermEditDates(data.startDate,data.endDate,index,'end')"
                            onkeydown="return false" 
                            v-validate="'required'"
                            :name="index + 'editend'"
                            class="form-control form-input"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classEditvalidate.${index + 'editend'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >End Date is required</label
                          >
                        </div>
                        <div class="cell" data-title="Teacher" v-else>
                           <input
                            type="date"
                            :min="data.startDate"
                            :max="maxDate"
                            :disabled="(!classEditObj.terms[index - 1].startDate || !classEditObj.terms[index - 1].endDate || !data.startDate)"

                            v-model="data.endDate"
                            @input="setTermEditDates(data.startDate,data.endDate,index,'end')"
                            onkeydown="return false" 
                            v-validate="'required'"
                            :name="index + 'editend'"
                            class="form-control form-input"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classEditvalidate.${index + 'editend'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >End Date is required</label
                          >
                        </div>
                       <div class="cell" data-title="Teacher" style="padding-left: 5%;">
                          {{ data.months }}
                        </div>
                        <div class="cell" data-title="Teacher" >
                            <button v-if="!data._id" :disabled="index != (classEditObj.terms.length - 1)" class="btn round red tooltipt" @click.prevent="deleteEditTerms(index)">
                            <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
                </div> -->
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
             <!-- <div class="dk_icon">
               
              </div> -->
            
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn" 
                  @click.prevent="updateClass"
                >
                    <span>Update</span>
                </button>
                <button
                  @click.prevent="closeUpdatePopUp"
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
     <b-modal id="editSection" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeSectionUpdatePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Update Classrooms</h2>
        <br />
        <div>
          <form
            data-vv-scope="sectionvalidate"
            id="stuprofile"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    @input="checkSectionName(editSection.name)"
                    v-validate="'required'"
                    type="text"
                    name="class Name"
                    v-model="editSection.name"
                    class="form-control form-input"
                    id="clsnameid"
                    placeholder="Class Name"
                  />
                 <label
                    v-if="errors.first('sectionvalidate.class Name') || !isSectionNameAvailable "
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('sectionvalidate.class Name') ? 'Section name is required ' : 'Section name already exist. please use a different one.' }} 
                    </label
                  >

                  <label for="clsnameid" v-else
                    >Section Name <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
             <!-- <div class="dk_icon">
               
              </div> -->
            
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateSection"
                >
                    <span>Update</span>
                </button>
                <button
                  @click.prevent="closeSectionUpdatePopUp"
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
     <b-modal id="createSubjectPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeSubjectPopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>{{ editSubjectId ? 'Update Subject' : 'Create Subject' }}</h2>
        <br />
        <div>
          <form
            data-vv-scope="subjectvalidate"
            id="stuprofile"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    @input="checkSubjectName(subject.subjectName)"
                    v-validate="'required'"
                    type="text"
                    name="Subject Name"
                    v-model="subject.subjectName"
                    class="form-control form-input"
                    id="SubjectNameid"
                    placeholder="Subject Name"
                  />
                 <label
                    v-if="errors.first('subjectvalidate.class Name') || !isSubjectNameAvailable "
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('subjectvalidate.class Name') ? 'Subject name is required ' : 'Subject name already exist. please use a different one.' }} 
                    </label
                  >

                  <label for="clsnameid" v-else
                    >Subject Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 mt-3">
                 <div class=" chkbox" data-title="Select">
                  <label class="custcheckbox">
                    <input type="checkbox"  v-model="subject.isMandtory"  />
                    <span class="checkmarkchk"></span>
                    
                  </label>
                  <label style="margin-left:35px">Mandatory</label>
                </div>
              </div> -->
                <div class="form-group input-group col-md-6 mt-3">
                 <div class=" chkbox" data-title="Select">
                  <label class="custcheckbox">
                    <input type="checkbox"  v-model="subject.isCurricularActivities"  />
                    <span class="checkmarkchk"></span>
                    
                  </label>
                  <label style="margin-left:35px">Curricular Activities</label>
                </div>
              </div>
              
            </div>
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
            
            
              <div class="text-center dk_iconsml">
                
                <button v-if="editSubjectId"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateSubject"
                >
                    <span>Update</span>
                </button>
                <button v-else
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="createSubject"
                >
                    <span>Create</span>
                </button>
                <button
                  @click.prevent="closeSubjectPopUp"
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal id="createSectionPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeCreateSectionPopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Create Classrooms</h2>
        <br />
        <div>
          <form
            data-vv-scope="sectionCreateValidate"
            id="stuprofile"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                    v-validate="'required'"
                    name="sectionObjClass"
                    class="form-control form-input"
                    id="sectionObjClassid"
                    v-model="sectionObj.class"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in createAllClassList"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                 <label
                    v-if="errors.first('sectionCreateValidate.sectionObjClass')"
                    class="vi-error"
                    style="top: -1em"
                    >Class is required</label
                  >
                  <label for="deptid" v-else
                    >Class <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    name="sectionObjSection"
                    v-model="sectionObj.sections"
                    class="form-control form-input"
                    id="sectionObjSectionid"
                    placeholder="Class Section"
                  />

                  <label
                    v-if="errors.first('sectionCreateValidate.sectionObjSection')"
                    class="vi-error"
                    style="top: -1em"
                    >Section is required</label
                  >

                  <label for="clsectid" v-else
                    >Section <span class="required">*</span>(eg: Ukg,Lkg)</label
                  >
                  
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select class="form-control form-input" id="teacherId">
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in teacherList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.firstName }}
                          </option>
                           
                        </select>
                        <label for="teacherId"
                          >Teacher <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-5 mt-3">
                      <span class="has-float-label">
                        <select class="form-control form-input" id="subjectId" >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in subjectList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.subjectName }}
                          </option>
                        </select>
                        <label for="subjectId"
                          >Subject <span class="required">*</span></label
                        >
                      </span>
                    </div>
            </div>
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
             <!-- <div class="dk_icon">
               
              </div> -->
            
              <div class="text-center dk_iconsml">
                
                
                <button 
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="createSection"
                >
                    <span>Create</span>
                </button>
                <button
                  @click.prevent="closeCreateSectionPopUp"
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    
    <b-modal id="deleteClassPopup" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeClassDeletePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button type="button" @click.prevent="closeClassDeletePopUp" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
     <b-modal id="deletesubjectPopup" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeSubjectDeletePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmSubjectDelete"
          >
              <span>Confirm</span>
          </button>
          <button type="button" @click.prevent="closeSubjectDeletePopUp"  class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
     <b-modal id="deleteSectionPopup" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeSectionDeletePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmSectionDelete"
          >
              <span>Confirm</span>
          </button>
          <button type="button" @click.prevent="closeSectionDeletePopUp"  class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="viewSectionPopUp" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeSectionPopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Assign Subjects To class</h2>
        <br />
        <div>
          <div class="row">
            <div class="col-lg-12">
              <div class="widjet">
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell"> <label class="custcheckbox">
                            <input type="checkbox" v-model="selectAll" @input.prevent="selectAllSubject(selectAll)" />
                            <span class="checkmarkchk"></span>
                          </label></div>
                      <div class="cell" style="text-align: center;">Subject Name</div>
                      <div class="cell" style="text-align: center;">Curricular Activities	</div>
                    </div>
                    <div class="resrow" v-for="(sec, index) in classSubject" :key="index">
                       <div class="cell chkbox" data-title="Select">
                          <label class="custcheckbox">
                            <input type="checkbox" v-model="sec.isSelected" />
                            <span class="checkmarkchk"></span>
                          </label>
                        </div>
                        <div class="cell" data-title="DepartMent" style="text-align: center;">{{ sec.subjectName }}</div>
                        <div class="cell" data-title="DepartMent" style="text-align: center;">{{ sec.isCurricularActivities ? 'Yes' : 'No' }}</div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          > 
          <button  @click.prevent="assignToClass" type="button" class="btn btnsml" >
            Assign
          </button>
          <!-- <button
              @click.prevent="assignToClass"
              type="button"
              class="btn btncl"
              id="clbtn"
            > 
                 <span>Assign</span>
            </button> -->
            <button
              @click.prevent="closeSectionPopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
                 <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="assignTechersMdl" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeAssignPopUp">×</button>
      <assign-teachers
        :teachersList="teacherList"
        :subjectsList="subjectList"
        :classSubjects="assignedClassSubject"
        :assignedObjectList="assignedObjectList"
        :classAndSectionDetails="classAndSectionDetails"
        @updateClass="updateAssignTeacher"
        @closePoup="closeAssignPopUp"
      ></assign-teachers>
    </b-modal>
    <b-modal id="confirmPopup" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeActionPopUp">×</button>
      <!-- <div class="modal-content"> -->
         <h2>Create class/classroom</h2>
        <br />
        <div>
      <div class="contact-form">
       <div style="padding:10px;width:100%;background: rgb(225 229 237);text-align: center;border-radius: 21px;">
								<label class="custradio" style="
    margin-bottom: 0px;
    border-radius: 21px;
    width: 83px;">Class
								  <input type="radio" v-model="actionView" value="class" name="schedule" >
								  <span class="checkmark"></span>
								</label>
								<label class="custradio" style="
    margin-bottom: 0px;
    border-radius: 21px;
    width: 83px;">Classroom
								  <input type="radio"  v-model="actionView" value="classRoom" name="schedule">
								  <span class="checkmark"></span>
								</label>
							</div>
              <div class="text-center dk_iconsml" style="margin-top: 4%;">
                
                
                <button 
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  style="width: 130px;"
                  :disabled="!actionView"
                  @click.prevent="openAction"
                >
                    <span>Confirm</span>
                </button>
                <button
                  @click.prevent="closeActionPopUp"
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
      </div>
       </div>
      <!-- </div> -->
    </b-modal>
    </div>
    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
    />
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "Class",

  data() {
    return {
      classObj: {
        department: "",
        className: "",
        section: "",
        assignedTeachers: [],
      //  terms: []
      },
      classEditObj: {
        // department: "",
        className: "",
       // terms: []
      },
      subject: {
        subjectName: '',
        isMandtory: true,
        isCurricularActivities: false
      },
      editSection: {
        name: ''
      },
      sectionObj: {
       class: null,
       sections: ''
      },
      searchWords: '',
      skip: 0, // set the skip info
      limit: 1000, // set the limit info
      currentPageNumber: 1,
      subjectCurrentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      maxDate: null,
      minDate: null,
      subjectSelected: 1,
      countAll: true,
      totalCount: 0,
      subjectSkip: 0,
      selectAll: false,
      subjectlimit : 10,
      subjectsearchWords : '',
      subjectcountAll : true,
      subjectTotalCount : 0,
      subjectlastPageNumber : 1,
      departMentList: [],
      viLoader: null,
      classList: [],
      allClassList: [],
      teacherList: [],
      studentList: [],
      subjectList: [],
      subjectListView: [],
      academicYearList: [],
      assignedObjectList: [],
      assignedTeacherList: [],
      sectionList: [],
      showComponent: false,
      isClassNameAvailable: true,
      isSectionNameAvailable: true,
      classId: null,
      editId: null,
      editSectionId: null,
      sectionDeleteId: null,
      sectionClassDeleteId: null,
      editSectionClassId: null,
      deleteId: null,
      subjectDeleteId: null,
      sectionId: null,
      editSubjectId: null,
      deleteSubjectId: null,
      currentTab: 1,
      prevClassName: "",
      prevSubjectName: '',
      prevSectionName: '',
      classAndSectionDetails: {},
      isSubjectNameAvailable: true,
      actionView: '',
      fromClassList: [],
      fromSectionList: [],
      selectClass: null,
      selectSection: null,
      sectionGroupByClass: {},
      classSubject: [],
      assignedClassSubject: [],
      editSubjectAssignClassId: null
    };
  },

  created() {
    this.getStudentList();
    this.getDepartMentList();
    this.getClassList(true);
    this.getSectionList();
    this.getTeacherList();
    this.getSubjectList();
    this.getSubjectListView();
    this.getAcademicYearList();
    this.getAllClassList();
    this.getClassAndSectionList()
  },
  computed: {
     styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
      getUserName(){
        let userData = secureUI.sessionGet("user");
        return userData.userDetails.firstName + " " + userData.userDetails.lastName
      },
      createAllClassList() {
      return this.allClassList.map(x => {
          return {
            label: x.className,
            value: x._id
          };
        });
    },
     
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				           this.countAll = true,
                  this.getClassList(false);
              }
          }
  },

  methods: {
    previousRoute(){
    //  let data = localStorage.getItem('previousTab');
    //  localStorage.setItem("activeTab",data);
    //  localStorage.setItem("previousTab",data);
    //  this.$router.go(-1)
        localStorage.setItem("activeTab", '/admission/home');
        localStorage.setItem("previousTab", '/admission/home');
        this.$router.push({ path: '/admission/home' });
    },
    // async navToSelectedPage(selectedPageNo) {

    //   this.skip = (this.limit * (selectedPageNo - 1));

    //   this.countAll = false

    //   this.getClassList(false);
    // },
    async navToSelectedPage(selectedPageNo) {

      this.subjectSkip = (this.subjectlimit * (selectedPageNo - 1));

      this.subjectcountAll = false

      this.getSubjectListView();
    },

    async goToSubjectNextPage() {
      this.subjectcountAll = false
      this.subjectCurrentPageNumber = this.subjectCurrentPageNumber + 1;
      this.subjectSelected = this.subjectCurrentPageNumber;
      this.subjectSkip = (this.subjectlimit * (this.subjectCurrentPageNumber - 1));

      this.getSubjectListView();
    },

    async goToSubjectPreviousPage() {
       this.subjectcountAll = false
      this.subjectCurrentPageNumber = this.subjectCurrentPageNumber - 1;
      this.subjectSelected = this.subjectCurrentPageNumber;
      this.subjectSkip = (this.subjectlimit * (this.subjectCurrentPageNumber - 1));
     
      this.getSubjectListView();
    },
    selectAllSubject(value){


      if(value){
      for(let x of this.classSubject){
          x.isSelected = false;
      }
      
      }else{
        for(let x of this.classSubject){
          x.isSelected = true;
        }
      }		
      },
    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getClassList(false);
    },
    
    

    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getClassList(false);
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    redirectRouteToViewStudent(sectionId,classId){
       this.$router.push(`/admission/view/${classId}/student/${sectionId}`);
    },
    getStudentCount(classId) {
      const studentListCount = this.studentList.filter(
        (x) => x.class == classId
      );
      return studentListCount.length > 0 ? studentListCount.length : 0;
    },
    addTerms(){
      this.classObj.terms.push(
        {
          name: 'Term' + " " + (this.classObj.terms.length + 1),
          startDate: null,
          endDate: null,
          months: 0
        }
      )
    },
    deleteTerms(index){
      this.classObj.terms.splice(index,1)
    },
    deleteEditTerms(index){
      this.classEditObj.terms.splice(index,1)
    },
     addEditTerms(){
      this.classEditObj.terms.push(
        {
          name: 'Term' + " " + (this.classEditObj.terms.length + 1),
          startDate: null,
          endDate: null,
          months: 0
        }
      )
    },
    

     setTermDates(startDate,endDate,index,type){
      if(startDate && endDate){
        let d1 = new Date(startDate)
        let d2 = new Date(endDate)

        let months = 0;

        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth() + 1;
        

        if(months == 13){
            months = months - 1
        }
        
        this.classObj.terms[index].months = months

      }else{
       this.classObj.terms[index].months = 0

      }

      this.classObj.terms.forEach((x, i) => {
      if (i > index) {
      x.startDate = null
      x.endDate = null
      x.months = null
      } 
      if(i == index){
        if(type == 'start'){
          x.endDate = null,
          x.months = null
        }
      }
    });
       
    },
    setminStartDate(date){
      let termStartDate = new Date(date)
      termStartDate = new Date(termStartDate.setDate(termStartDate.getDate() + 1));

     let startMonth = String(termStartDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
     let startdate = String(termStartDate.getDate()).padStart(2, "0");
   
     return termStartDate.getFullYear() + "-" + startMonth + "-" + startdate
    },
    setTermEditDates(startDate,endDate,index,type){
      //this.classEditObj.terms

      if(startDate && endDate){
        let d1 = new Date(startDate)
        let d2 = new Date(endDate)

        let months = 0;

        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth() + 1;
        

        if(months == 13){
            months = months - 1
        }
        
        this.classEditObj.terms[index].months = months

      }else{
       this.classEditObj.terms[index].months = 0

      }

      this.classEditObj.terms.forEach((x, i) => {
      if (i > index) {
      x.startDate = null
      x.endDate = null
      x.months = null
      } 
      if(i == index){
        if(type == 'start'){
          x.endDate = null,
          x.months = null
        }
      }
    });
       
       
    },
    
    getAssignedSubjectCount(subId){
      const teacherListCount = this.assignedTeacherList.filter(
        (x) => x.subjectId == subId
      );
      return teacherListCount.length > 0 ? true : false;
    },
    getClassName(classid) {
      const classDetails = this.allClassList.find((x) => x._id == classid);

      if (classDetails) {
        return classDetails.className;
      } else {
        return "";
      }
    },
     getSubjectName(id) {
      const subDetails = this.subjectList.find((x) => x._id == id);

      if (subDetails) {
        return subDetails.subjectName;
      } else {
        return "";
      }

    },
    setActiveTab(index){
      this.currentTab = index
    },
    closeUpdatePopUp(){
      this.$bvModal.hide("editclass");
      this.editId = null
      this.minDate = null
      this.maxDate = null
      this.classEditObj.department = "",
      this.classEditObj.className = "",
     // this.classEditObj.terms = [],
      
      this.isClassNameAvailable = true
    },
    showEditModal(id){
      const details = this.classList.find((x) => x._id == id)
      this.editId = id
      this.classEditObj.department = details.department,
      this.classEditObj.className = details.className,
      this.prevClassName = details.className
      this.$bvModal.show("editclass")

    },
    setToClassAndSection(classId){
		
		if(classId){
		  this.fromSectionList = []        
		  const findByClassIdSections = this.fromClassList.find( x => x._id == classId)
		  this.fromSectionList = findByClassIdSections.sections
      
		}else{
			this.fromSectionList = []
      this.selectSection = null
		}
    this.getSectionList()
	 },
   getSectionRecords(sectionId){
	
    this.getSectionList()
	 },
   
    showSectionEditModal(id,data){
      const cloneDetails = this.sectionGroupByClass[data]
      const details = cloneDetails.find((x) => x._id == id)
      
      this.editSectionId = id,
      this.editSectionClassId = details.class._id,
      this.editSection.name = details.name,
      this.prevSectionName = details.name,
      this.isSectionNameAvailable = true
      this.$bvModal.show("editSection")
    },
    showClassEditModal(data){
      const details = data[0].class
      this.editId = details._id
     // this.classEditObj.terms = details.terms ? details.terms : [],
      this.classEditObj.className = details.className,
      this.prevClassName = details.className
      this.$bvModal.show("editclass")

      let userData = secureUI.sessionGet("user");

      let termStartDate = new Date(userData.userDetails.account.currentAcademicYear.from)
      let termEndDate = new Date(userData.userDetails.account.currentAcademicYear.to)


      let lastDay = new Date(termEndDate.getFullYear(), termEndDate.getMonth() + 1, 0);

      const startMonth = String(termStartDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
      const startdate = String(termStartDate.getDate()).padStart(2, "0");

      const endMonth = String(termEndDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
      const enddate = String(lastDay.getDate()).padStart(2, "0");


      let totalMonths = 0;

      totalMonths = (termEndDate.getFullYear() - termStartDate.getFullYear()) * 12;
      totalMonths -= termStartDate.getMonth();
      totalMonths += termEndDate.getMonth() + 1;


      if(totalMonths == 13){
          totalMonths = totalMonths - 1
      }

     
      this.minDate = termStartDate.getFullYear() + "-" + startMonth + "-" + startdate
      this.maxDate = termEndDate.getFullYear() + "-" + endMonth + "-" + enddate

      //  for (let x of this.classEditObj.terms){
      //     x.startDate = x.startDate ? x.startDate.substring(0,10) : null,
      //     x.endDate = x.endDate ? x.endDate.substring(0,10) : null
      //  }

   
    },
    openAssignSubjectClass(data){
      const details = data[0].class
     
     this.editSubjectAssignClassId = details._id

     if(details.assignSubject && details.assignSubject.length > 0){
      for(let x of this.subjectList){

        if(details.assignSubject.includes(x._id)){
          this.classSubject.push({
              subject: x._id,
              isSelected: true,
              subjectName: x.subjectName || '',
              isMandtory: x.isMandtory || false,
              isCurricularActivities: x.isCurricularActivities || false
            })
        }else{
          this.classSubject.push({
              subject: x._id,
              isSelected: false,
              subjectName: x.subjectName || '',
              isMandtory: x.isMandtory || false,
              isCurricularActivities: x.isCurricularActivities || false
            })
        }

        // for(let y of details.assignSubject){
        //   if(x._id == y){
        //     this.classSubject.push({
        //       subject: x._id,
        //       isSelected: true,
        //       subjectName: x.subjectName || '',
        //       isMandtory: x.isMandtory || false,
        //       isCurricularActivities: x.isCurricularActivities || false
        //     })
        //   }else{
        //     this.classSubject.push({
        //       subject: x._id,
        //       isSelected: false,
        //       subjectName: x.subjectName || '',
        //       isMandtory: x.isMandtory || false,
        //       isCurricularActivities: x.isCurricularActivities || false
        // })
        //   }
        // }
      }
     }else{
      for(let x of this.subjectList){
        this.classSubject.push({
          subject: x._id,
          isSelected: false,
          subjectName: x.subjectName || '',
          isMandtory: x.isMandtory || false,
          isCurricularActivities: x.isCurricularActivities || false
        })
      }
     }
     
     this.$bvModal.show("viewSectionPopUp")

     
    },
    
    closeSectionUpdatePopUp(){
      this.isSectionNameAvailable = true
      this.editSectionId = null,
      this.editSectionClassId = null,
      this.$bvModal.hide("editSection")
    },
    
    showEditSubjectModal(id){
      const details = this.subjectList.find((x) => x._id == id)
      this.editSubjectId = id
      this.subject.subjectName = details.subjectName,
       this.subject.isMandtory = details.isMandtory,
      this.subject.isCurricularActivities = details.isCurricularActivities || false,
       
      
      this.prevSubjectName = details.subjectName
      this.$bvModal.show("createSubjectPopUp")
    },
    showSubjectPopUp(){
       this.$bvModal.show("createSubjectPopUp")
    },
    showSectionPopUp(){
      this.$bvModal.show("confirmPopup")
    },
    closeCreateSectionPopUp(){
      this.$bvModal.hide("createSectionPopUp")
      this.sectionObj.class = null,
      this.sectionObj.sections = ''
     
    },
    openAction(){
     if(this.actionView == 'class'){
            let userData = secureUI.sessionGet("user");

            let termStartDate = new Date(userData.userDetails.account.currentAcademicYear.from)
            let termEndDate = new Date(userData.userDetails.account.currentAcademicYear.to)
            

            let lastDay = new Date(termEndDate.getFullYear(), termEndDate.getMonth() + 1, 0);

            const startMonth = String(termStartDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
            const startdate = String(termStartDate.getDate()).padStart(2, "0");

            const endMonth = String(termEndDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
            const enddate = String(lastDay.getDate()).padStart(2, "0");


            let totalMonths = 0;

            totalMonths = (termEndDate.getFullYear() - termStartDate.getFullYear()) * 12;
            totalMonths -= termStartDate.getMonth();
            totalMonths += termEndDate.getMonth() + 1;
            
    
            if(totalMonths == 13){
                totalMonths = totalMonths - 1
            }

        //     this.classObj.terms.push(
        //       {
        //         name: 'Term 1',
        //         startDate: termStartDate.getFullYear() + "-" + startMonth + "-" + startdate,
        //         endDate: null,
        //        // endDate: termEndDate.getFullYear() + "-" + endMonth + "-" + enddate,
        //         months: null
        //       }
        //     )
        // this.minDate = termStartDate.getFullYear() + "-" + startMonth + "-" + startdate
        // this.maxDate = termEndDate.getFullYear() + "-" + endMonth + "-" + enddate

        this.$bvModal.show("addclass")
     }else{
        this.$bvModal.show("createSectionPopUp")
     }
     this.closeActionPopUp()
    },
    closeActionPopUp(){
     this.$bvModal.hide("confirmPopup")
     this.actionView = ''
    },
    
    async checkClassName(value) {
        if(value){
        let allowDupUser = true;

        if (value) {
          // check the current key value is changes
          if ( value == this.prevClassName) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {
           value = value.trim()
           const details = this.classList.find((x) => x.className == value);
           if(details){
              this.isClassNameAvailable = false
           }else{
              this.isClassNameAvailable = true
           }
        }
        }
    },
    
    async checkSectionName(value) {
        if(value){
        value = value.toUpperCase()

        let allowDupUser = true;

        if (value) {
          // check the current key value is changes
          if ( value == this.prevSectionName) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {
           value = value.trim()
           
           const details = this.sectionList.find((x) => (x.name == value) && (x.class._id == this.editSectionClassId));
           if(details){
              this.isSectionNameAvailable = false
           }else{
              this.isSectionNameAvailable = true
           }

        
        }
        }
    },
    
    async checkSubjectName(value) {
        if(value){
        let allowDupUser = true;

        if (value) {
          if ( value == this.prevSubjectName) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {

           const subDetails = this.subjectList.find((x) => x.subjectName.toLowerCase() == value.toLowerCase());
           if(subDetails){
              this.isSubjectNameAvailable = false
           }else{
              this.isSubjectNameAvailable = true
           }

        }
        }
    },
    
    getuniqeTeachers(data) {
      if (data) {
        const ids = data.map((x) => x.teacherId);
        return ids.filter((item, index) => ids.indexOf(item) === index);
      } else {
        return "";
      }
    },
    showdrop() {
      var showdp = document.getElementById("showdropmenu");

      if (showdp.style.display === "block") {
        showdp.style.display = "none";
      } else {
        showdp.style.display = "block";
      }
      setTimeout(function () {
        showdp.style.display = "none";
      }, 10000);
      return false;
    },
    getDepartName(departMentId) {
      if (departMentId) {
         const departmentDetails = this.departMentList.find((x) => x._id == departMentId);

          if (departmentDetails) {
            return departmentDetails.departmentName;
          } else {
            return "";
          }

      } else {
        return "";
      }
    },
    getTeacherName(teacherId) {
      
      if (teacherId) {
         const teacherDetails = this.teacherList.find((x) => x._id == teacherId);
          
          if (teacherDetails) {
            
            var name = teacherDetails.firstName + ' ' + teacherDetails.lastName;
            return name;
          } else {
            return "";
          }

      } else {
        return "";
      }
    },
    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deleteClassPopup");
    },
     showSubjectDeleteModal(id) {
      this.subjectDeleteId = id;
      this.$bvModal.show("deletesubjectPopup");
    },
    showSectionDeleteModal(id,classid) {
      this.sectionDeleteId = id;
      this.sectionClassDeleteId = classid;
      this.$bvModal.show("deleteSectionPopup");
    },
    
    closeSubjectPopUp(){
         this.prevSubjectName = ""
         this.editSubjectId = null
         this.subject.subjectName = ''
         this.subject.isMandtory = true
         this.subject.isCurricularActivities = false
         
         this.$bvModal.hide("createSubjectPopUp");
    },
    async getSectionAll(classId) {

       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/class/getSection?classId=${classId}`,
          userData.token
        );
        if (response.isSuccess) {
          this.sectionList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }

      this.$bvModal.show("viewSectionPopUp");
    },
    closeSectionPopUp(){
       this.classSubject = []
       this.editSubjectAssignClassId = null
       this.selectAll = false
       this.$bvModal.hide("viewSectionPopUp");
    },
    async assignToClass() {
      let isFormValid = false;

      
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          let checkSelected = this.classSubject.filter( x => x.isSelected)

          let getSubjectIds = checkSelected.map( x => x.subject)
          
         // this.viLoader = true;
          let obj = {
            _id: this.editSubjectAssignClassId,
            assignSubject : getSubjectIds
          }
          const response = await ViService.viXPost(
            "/class/updateAssignSubject",
            obj,
            userData.token
          );
         // this.viLoader = null;

          if (response.isSuccess) {
           // this.selectAll = false
            this.selectAll = false
            this.closeSectionPopUp();
            this.getClassList(false);
            this.getAllClassList()
            this.getSectionList()
           // this.getTeacherList();

            this.$toasted.success(response.message);

            // let getCounts = this.$store.getters.getAdmissionObjCounts
            // getCounts.class = getCounts.class + 1

          // this.$store.dispatch('setAdmissionObjCOunt', getCounts);

          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
    },
    // assignToClass(){
    // },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async createClass() {
      let isFormValid = false;

      await this.$validator.validateAll("classvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isClassNameAvailable) {

        let sections = this.classObj.section.split(',')

        const invalidSections = [];
        const validSections = [];

        for (let i = 0; i < sections.length; i++) { 
          
            if(validSections.indexOf(sections[i].trim()) != -1){
            invalidSections.push(sections[i].trim())
          }else{
            validSections.push(sections[i].trim())
          }
        }
       if(invalidSections.length == 0){
     //   if(this.classObj.terms.length > 0){
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
         // this.viLoader = true;
          
          const response = await ViService.viXPost(
            "/class/createClass/paidSchool",
            this.classObj,
            userData.token
          );
         // this.viLoader = null;

          if (response.isSuccess) {
            this.closePopUp();
            this.getClassList(false);
            this.getAllClassList()
            this.getSectionList()
            this.getTeacherList();

            this.$toasted.success(response.message);

            let getCounts = this.$store.getters.getAdmissionObjCounts
            getCounts.class = getCounts.class + 1

           this.$store.dispatch('setAdmissionObjCOunt', getCounts);

          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
     
      // }else{
      //   this.$toasted.error('Minimum One Term Required');
      // }
      }else{
        this.$toasted.error('Section Duplicates. Please check');
      }

      }
    },
    async createSection() {
      let isFormValid = false;

      await this.$validator.validateAll("sectionCreateValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid ) {
       

        let sections = this.sectionObj.sections.split(',')

        const invalidSections = [];
        const validSections = [];

        for (let i = 0; i < sections.length; i++) { 
          
            if(validSections.indexOf(sections[i].trim()) != -1){
            invalidSections.push(sections[i].trim())
          }else{
            validSections.push(sections[i].trim())
          }
        }
       if(invalidSections.length == 0){
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
         // this.viLoader = true;

          const response = await ViService.viXPost(
            "/class/createSection",
            this.sectionObj,
            userData.token
          );

          if (response.isSuccess) {
            this.closeCreateSectionPopUp();
            this.getClassList(false);
            this.getAllClassList()
            this.getSectionList()
            this.getTeacherList();

            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }else{
        this.$toasted.error('Section Duplicates. Please check');
      }

      }
    },
    
     async createSubject() {
      let isFormValid = false;

      await this.$validator.validateAll("subjectvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isSubjectNameAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
         // this.viLoader = true;

          const response = await ViService.viXPost(
            "/class/createSubject",
            this.subject,
            userData.token
          );
          this.viLoader = null;

          if (response.isSuccess) {
            this.closeSubjectPopUp();
            this.getSubjectListView()
            this.getSubjectList();
            this.$toasted.success(response.message);
            let getCounts = this.$store.getters.getAdmissionObjCounts
            getCounts.subject = getCounts.subject + 1

           this.$store.dispatch('setAdmissionObjCOunt', getCounts);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    
    async updateClass() {
      let isFormValid = false;

      await this.$validator.validateAll("classEditvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isClassNameAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) { 
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
         // this.viLoader = true;
          const response = await ViService.viXPut(`/class/updateClass/${this.editId}`,this.classEditObj,userData.token);
          this.viLoader = null;

          if (response.isSuccess) {
            this.closeUpdatePopUp();
            this.getClassList(false);
            this.getSectionList()
            this.getTeacherList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
     async updateSection() {
      let isFormValid = false;

      await this.$validator.validateAll("sectionvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isSectionNameAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) { 
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          //this.viLoader = true;
          const response = await ViService.viXPut(`/class/updateSection/${this.editSectionId}`,this.editSection,userData.token);
          this.viLoader = null;

          if (response.isSuccess) {
            this.closeSectionUpdatePopUp();
            this.getClassList(false);
            this.getSectionList()
            this.getTeacherList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
     async updateSubject() {
      let isFormValid = false;

      await this.$validator.validateAll("subjectvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isSubjectNameAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) { 
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
         // this.viLoader = true;
          const response = await ViService.viXPut(`/class/updateSubject/${this.editSubjectId}`,this.subject,userData.token);
          this.viLoader = null;

          if (response.isSuccess) {
            this.closeSubjectPopUp();
            this.getSubjectListView()
            this.getSubjectList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    
    async getDepartMentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/class/getDepartMentList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.departMentList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },

    async getTeacherList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/teacher/getUserTeacherList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.teacherList = secureUI.secureGet(response.data).teacherList;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    closePopUp() {
      this.$bvModal.hide("addclass");
      (this.classObj.department = ""),
        (this.classObj.className = ""),
        (this.classObj.section = ""),
        (this.classObj.assignedTeachers = []);
        this.isClassNameAvailable = true
       // this.classObj.terms = []

    },
    async getSubjectList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/class/getSubjectList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
     async getSubjectListView() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
      
        const response = await ViService.viXGet(
          `/class/getSubjectListView?skip=${this.subjectSkip}&limit=${this.subjectlimit}&search=${this.subjectsearchWords}&isCount=${this.subjectcountAll}`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          
          this.subjectListView = resultData.subjectList
            
          if(this.subjectcountAll){
            this.subjectTotalCount = resultData.totalCount;
            this.subjectlastPageNumber = Math.ceil(this.subjectTotalCount/this.subjectlimit);

          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }

        // this.viLoader = false
       
      }
    },

    async getClassList(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        if(value){
           this.viLoader = true;
        }
        const response = await ViService.viXGet(`/class/getAllClassesListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,userData.token);

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
           this.classList = resultData.classlist

          if(this.countAll){
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
          }
         
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
         this.viLoader = false;
      }
    },
    async getAllClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/class/getAllClasses?viewType=list`,userData.token);

        if (response.isSuccess) {
          this.allClassList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getClassAndSectionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {

        const response = await ViService.viXGet("/class/getAllClassesAndSection?viewType=list",userData.token);

        if (response.isSuccess) {

            const classResult = secureUI.secureGet(response.data);

            this.fromClassList = classResult

            this.$toasted.success(response.message);

        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    
    async getSectionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
       // this.viLoader = true;

       const response = await ViService.viXGet(`/class/getAllSectionListAndClass?class=${this.selectClass}&section=${this.selectSection}`,userData.token);

      
        if (response.isSuccess) {
          this.assignedTeacherList = []
          this.sectionList = []
          const details = secureUI.secureGet(response.data);
          
          this.sectionGroupByClass = details.sectionGroupByClass
          
          this.sectionList = details.sections
          this.assignedTeacherList = details.assignedTeachers

          let getCounts = this.$store.getters.getAdmissionObjCounts
          getCounts.section = this.sectionList.length

          this.$store.dispatch('setAdmissionObjCOunt', getCounts);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },

    async enableAssignTeachersModal(classid,index,data) {

      const details = classid[0].class
       let classSubject = []
    // this.editSubjectAssignClassId = details._id

     if(details.assignSubject && details.assignSubject.length > 0){
      for(let x of this.subjectList){

        if(details.assignSubject.includes(x._id)){
          classSubject.push({
              subject: x._id,
              isSelected: true
            })
        }else{
          classSubject.push({
              subject: x._id,
              isSelected: false,
            })
        }

        
      }
     }

     let checkData = classSubject.filter( x => x.isSelected)

     if(checkData.length > 0){
       const classDetails = this.sectionGroupByClass[data][index]
       
       this.assignedClassSubject = classDetails.class.assignSubject || []
       this.classAndSectionDetails['sectionName'] = classDetails.name
       this.classAndSectionDetails['className'] = classDetails.class.className
       this.classId = classDetails.class._id;
       this.sectionId = classDetails._id;
       this.assignedObjectList = classDetails.assignedTeachers || [];
       this.showComponent = true;
       this.$bvModal.show("assignTechersMdl");
     }else{
      await this.openAssignSubjectClass(classid)
     }
     
     
      
    },
    closeAssignPopUp() {
      this.$bvModal.hide("assignTechersMdl");
    },
    closeClassDeletePopUp(){
     this.$bvModal.hide("deleteClassPopup");
     this.deleteId = null;
    },

    async updateAssignTeacher(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
     //   this.viLoader = true;

        let obj = {
          class: this.classId,
          section: this.sectionId,
          assignedTeachers: value,
        };
        const response = await ViService.viXPost(
          "/class/updateAssignTeacher",
          obj,
          userData.token
        );
        this.viLoader = null;
        if (response.isSuccess) {
          this.$bvModal.hide("assignTechersMdl");
          this.getClassList(false);
          this.getSectionList()
          this.getTeacherList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },

    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/contact/getStudentList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.studentList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
       // this.viLoader = true;
        const response = await ViService.viDelete(
          `/class/deleteClass/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$bvModal.hide("deleteClassPopup");
          this.deleteId = null;
          this.getClassList(false);
            let getCounts = this.$store.getters.getAdmissionObjCounts
            getCounts.class = getCounts.class - 1

           this.$store.dispatch('setAdmissionObjCOunt', getCounts);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    closeSubjectDeletePopUp(){
     this.$bvModal.hide("deletesubjectPopup");
     this.subjectDeleteId = null;
    },
    closeSectionDeletePopUp(){
     this.$bvModal.hide("deleteSectionPopup");
     this.sectionDeleteId = null;
     this.sectionClassDeleteId = null
    },
    
    async confirmSubjectDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/class/deleteSubject/${this.subjectDeleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$bvModal.hide("deletesubjectPopup");
          this.subjectDeleteId = null;
                      this.getSubjectListView()

          this.getSubjectList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async confirmSectionDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
       // this.viLoader = true;
        const response = await ViService.viDelete(
          `/class/deleteSection/${this.sectionDeleteId}/${this.sectionClassDeleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$bvModal.hide("deleteSectionPopup");
          this.sectionDeleteId = null;
          this.sectionClassDeleteId = null
          this.getSectionList()
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  },

  components: {
    ViSpinner,
    "assign-teachers": () => import("./ViAdmissionAssignTeacher.vue"),
  
  },
};
</script>
<style lang="scss">
#confirmPopup .modal-content {
    width: 49% !important; /* Could be more or less, depending on screen size */
}
#dropdown-1__BV_toggle_ {
  width: 28px !important;
    height: 28px !important;
}
</style>